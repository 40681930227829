import request from '@/utils/request'

// 查询平台站点管理配置
export function getPlatformSitePage(reqParams) {
  return request({
    url: `/platformSite/getList`,
    method: 'post',
    data: reqParams
  })
}
// 查询平台，站点，账号三级联动关系
export function getPlatformInfo(reqParams) {
  return request({
    url: `/platformSite/getPlatformInfo`,
    method: 'get',
    params: reqParams
  })
}
// 添加配置
export function platformSiteAdd(reqParams) {
  return request({
    url: `/platformSite/add`,
    method: 'post',
    data: reqParams
  })
}
// 配置
export function platformSiteDelete(id) {
  return request({
    url: '/platformSite/deleteById/' + id,
    method: 'delete'
  })
}
// 配置
export function platformUpdate(reqParams) {
  return request({
    url: '/platformSite/updateById',
    method: 'put',
    data: reqParams
  })
}
